























import { defineComponent } from 'vue-demi';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { default as data } from './data.json';
import TocLink from './TocLink.vue';

export default defineComponent({
  components: {
    TocLink,
  },

  setup() {
    return {
      data,
    };
  },
});
