












import { defineComponent, ref, watch } from 'vue-demi';
import { partsDb } from '../db/PartsDb';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },

  // eslint-disable-next-line
  setup(props) {
    const isPdf = ref(false);
    const toFinal = ref('');

    watch(
      () => props.to,
      (value) => {
        if (/\.pdf/.test(value)) {
          partsDb
            .getFile(`pdf/${value}`)
            .then((file) => {
              if (file) {
                toFinal.value = `/pdfjs/web/viewer.html#file=${URL.createObjectURL(
                  file,
                )}&name=${value}`;
                isPdf.value = true;
              }
            })
            .catch(() => {
              /* Silent error */
            });
        } else {
          toFinal.value = value;
        }
      },
      {
        immediate: true,
      },
    );

    return {
      props,
      toFinal,
      isPdf,
    };
  },
});
